import { pushRuntimeValueToStack } from "../stackManip";
import { arity, validateType } from "../util";
const reduce = arity(3, (args, stack, exec) => {
    const target = validateType("array", exec(args[2], stack));
    const newValue = target.reduce((acc, cur) => {
        const accCurPair = [acc, cur];
        const newStack = pushRuntimeValueToStack(accCurPair, stack);
        return exec(args[0], newStack);
    }, exec(args[1], stack));
    return newValue;
});
export default reduce;
