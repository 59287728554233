import { castToString } from "../runtimeValues";
import { arity, validateType } from "../util";
const fromentries = arity(1, (args, stack, exec) => {
    const source = validateType("array", exec(args[0], stack));
    const retval = {};
    source.forEach((item) => {
        const [key = null, value = null] = validateType("array", item);
        retval[castToString(key)] = value;
    });
    return retval;
});
export default fromentries;
