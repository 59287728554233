import { RuntimeError } from "../errors";
import { getType } from "../runtimeValues";
import { arity, validateType } from "../util";
const match = arity(2, (args, stack, exec) => {
    const matcher = exec(args[0], stack);
    const target = validateType("string", exec(args[1], stack));
    if (getType(matcher) === 'regex') {
        return matcher.test(target);
    }
    else if (getType(matcher) === 'string') {
        return (new RegExp(matcher)).test(target);
    }
    else {
        throw new RuntimeError("Matching only works with strings or regexes");
    }
});
export default match;
