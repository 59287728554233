import { pushRuntimeValueToStack } from "../stackManip";
import { arity } from "../util";
import { getProperties } from "../runtimeValues";
const mapvalues = arity(2, (args, stack, exec) => {
    const evaluated = exec(args[1], stack);
    const results = {};
    getProperties(evaluated).forEach((key) => {
        results[key] = exec(args[0], pushRuntimeValueToStack(evaluated[key], stack));
    });
    return results;
});
export default mapvalues;
