import { RuntimeError } from "./errors";
import { getType } from "./runtimeValues";
export function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}
export const seqHelper = (arr, start = 0) => {
    const firstArray = arr[0];
    const result = [];
    for (let idx = start; idx < firstArray.length; idx++) {
        if (firstArray[idx]) {
            if (arr.length === 1) {
                result.push([idx]);
            }
            else {
                const subResult = seqHelper(arr.slice(1), idx + 1);
                for (let i = 0; i < subResult.length; i++) {
                    result.push([idx].concat(subResult[i]));
                }
            }
        }
    }
    return result;
};
// Builtin Helpers
export const arity = (arityCount, fn) => (args, stack, exec) => {
    const validArity = typeof arityCount === "number"
        ? arityCount === args.length
        : arityCount.indexOf(args.length) !== -1;
    if (!validArity) {
        throw new RuntimeError("Expected " + arityCount + " arguments, got " + args.length);
    }
    return fn(args, stack, exec);
};
export const validateType = (type, value) => {
    if (getType(value) !== type) {
        throw new RuntimeError("Expected type " + type + ", got " + getType(value));
    }
    return value;
};
